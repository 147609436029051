define('ember-debounced-properties/decorator', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = debouncedDecorator;

  var on = _ember['default'].on;
  var observer = _ember['default'].observer;
  var debounce = _ember['default'].run.debounce;
  var capitalize = _ember['default'].String.capitalize;

  function handleDescriptor(target, key, descriptor) {
    var originalParams = descriptor.__originalParams || [];
    var defaultWait = originalParams[0] || 1000;

    var debouncedPropertyName = 'debounced' + capitalize(key);
    var updateFunctionName = '__setDebounced' + capitalize(key);

    target[updateFunctionName] = function () {
      if (!this.isDestroying || !this.isDestroyed) {
        this.set(debouncedPropertyName, this.get(key));
      }
    };

    target['__init' + capitalize(debouncedPropertyName)] = on('init', function () {
      this[updateFunctionName]();
    });

    target['__' + debouncedPropertyName + 'DidChange'] = observer(key, function () {
      var wait = this.getWithDefault(key + 'Delay', defaultWait);

      if (wait > 0) {
        debounce(this, this[updateFunctionName], wait);
      } else {
        this[updateFunctionName]();
      }
    });

    return descriptor;
  }

  function isDescriptor(item) {
    return item && typeof item === 'object';
  }
  function debouncedDecorator() {
    for (var _len = arguments.length, params = Array(_len), _key = 0; _key < _len; _key++) {
      params[_key] = arguments[_key];
    }

    // determine if user called as @debounced(500, 'name') or @debounced
    if (isDescriptor(params[params.length - 1])) {
      return handleDescriptor.apply(undefined, arguments);
    } else {
      return function (target, key, descriptor) {
        descriptor.__originalParams = params;

        return handleDescriptor.apply(undefined, arguments);
      };
    }
  }
});