define('ember-buffered-proxy/mixin', ['exports', 'ember', 'ember-buffered-proxy/helpers'], function (exports, _ember, _emberBufferedProxyHelpers) {
  'use strict';

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var keys = Object.keys || _ember['default'].keys;
  var create = Object.create || _ember['default'].create;
  var isArray = _ember['default'].isArray;
  var computed = _ember['default'].computed;

  var hasOwnProp = Object.prototype.hasOwnProperty;

  exports['default'] = _ember['default'].Mixin.create({
    hasChanges: computed.readOnly('hasBufferedChanges'),
    applyChanges: (0, _emberBufferedProxyHelpers.aliasMethod)('applyBufferedChanges'),
    discardChanges: (0, _emberBufferedProxyHelpers.aliasMethod)('discardBufferedChanges'),

    init: function init() {
      this.initializeBuffer();
      this.hasBufferedChanges = false;
      this._super.apply(this, arguments);
    },

    initializeBuffer: function initializeBuffer(onlyTheseKeys) {
      if (isArray(onlyTheseKeys) && !(0, _emberBufferedProxyHelpers.empty)(onlyTheseKeys)) {
        onlyTheseKeys.forEach(function (key) {
          delete this.buffer[key];
        }, this);
      } else {
        this.buffer = create(null);
      }
    },

    unknownProperty: function unknownProperty(key) {
      var buffer = this.buffer;

      if (hasOwnProp.call(buffer, key)) {
        return buffer[key];
      } else {
        return this._super(key);
      }
    },

    setUnknownProperty: function setUnknownProperty(key, value) {
      var buffer = this.buffer;
      var content = this.get('content');
      var current;
      var previous;

      if (content != null) {
        current = get(content, key);
      }

      previous = hasOwnProp.call(buffer, key) ? buffer[key] : current;

      if (previous === value) {
        return;
      }

      this.propertyWillChange(key);

      if (current === value) {
        delete buffer[key];
        if ((0, _emberBufferedProxyHelpers.empty)(buffer)) {
          this.set('hasBufferedChanges', false);
        }
      } else {
        buffer[key] = value;
        this.set('hasBufferedChanges', true);
      }

      this.propertyDidChange(key);

      return value;
    },

    applyBufferedChanges: function applyBufferedChanges(onlyTheseKeys) {
      var buffer = this.buffer;
      var content = this.get('content');

      keys(buffer).forEach(function (key) {
        if (isArray(onlyTheseKeys) && !onlyTheseKeys.contains(key)) {
          return;
        }

        set(content, key, buffer[key]);
      });

      this.initializeBuffer(onlyTheseKeys);

      if ((0, _emberBufferedProxyHelpers.empty)(this.buffer)) {
        this.set('hasBufferedChanges', false);
      }
    },

    discardBufferedChanges: function discardBufferedChanges(onlyTheseKeys) {
      var buffer = this.buffer;

      this.initializeBuffer(onlyTheseKeys);

      keys(buffer).forEach(function (key) {
        if (isArray(onlyTheseKeys) && !onlyTheseKeys.contains(key)) {
          return;
        }

        this.propertyWillChange(key);
        this.propertyDidChange(key);
      }, this);

      if ((0, _emberBufferedProxyHelpers.empty)(this.buffer)) {
        this.set('hasBufferedChanges', false);
      }
    }
  });
});