define('ember-buffered-proxy-component/components/buffered-proxy', ['exports', 'ember', 'ember-buffered-proxy/proxy', 'ember-buffered-proxy-component/templates/components/buffered-proxy'], function (exports, _ember, _emberBufferedProxyProxy, _emberBufferedProxyComponentTemplatesComponentsBufferedProxy) {
  'use strict';

  var Component = _ember['default'].Component;

  exports['default'] = Component.extend({
    layout: _emberBufferedProxyComponentTemplatesComponentsBufferedProxy['default'],
    tagName: '',
    bufferedProxy: null,
    wrap: false,

    didInitAttrs: function didInitAttrs(_ref) {
      var attrs = _ref.attrs;

      var value = attrs.content.value;
      var content = this.get('wrap') ? { value: value } : value;

      this.set('bufferedProxy', _emberBufferedProxyProxy['default'].create({
        content: content
      }));
    },

    actions: {
      applyChanges: function applyChanges() {
        this.get('bufferedProxy').applyChanges();
        this.updateWrappedValue();
      },

      discardChanges: function discardChanges() {
        this.get('bufferedProxy').discardChanges();
        this.updateWrappedValue();
      }
    },

    updateWrappedValue: function updateWrappedValue() {
      if (this.get('wrap')) {
        this.set('content', this.get('bufferedProxy.value'));
      }
    }
  });
});