define('ember-aspect-ratio-image/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var htmlSafe = _ember['default'].String.htmlSafe;

  exports['default'] = Component.extend({
    classNames: ['aspect-ratio-image'],

    width: null,
    height: null,

    fillStyle: computed('width', 'height', function () {
      var width = parseInt(this.get('width'));
      var height = parseInt(this.get('height'));

      var percent = height / width * 100;
      return htmlSafe('padding-bottom: ' + percent + '%;');
    })
  });
});